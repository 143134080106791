import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ["wrapper", "overlay", "slideover", "close"]

  open() {
    enter(this.wrapperTarget)
    enter(this.overlayTarget)
    enter(this.slideoverTarget)
    enter(this.closeTarget)
  }

  close() {
    leave(this.wrapperTarget)
    leave(this.overlayTarget)
    leave(this.slideoverTarget)
    leave(this.closeTarget)
  }

  handleKeyup(e) {
    if (e.code == "Escape") {
      this.close()
    }
  }
}
