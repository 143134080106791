import Timeago from "stimulus-timeago"
import { ptBR } from 'date-fns/locale'

export default class extends Timeago {
  connect() {
    super.connect()
  }

  get locale () {
    return ptBR
  }
}
