import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["entries", "pagination", "paginationLoading"]

  loading = false;

  initialize() {
    let options = {
      rootMargin: '100px',
    }

    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
  }

  connect() {
    this.intersectionObserver.observe(this.paginationTarget)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.paginationTarget)
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.paginate()
      }
    })
  }

  async paginate() {
    let nextPage = this.paginationTarget.querySelector("a[rel='next']")

    if (nextPage == null) { return }
    if (this.loading) { return }

    let url = nextPage.href

    this.toggleLoading()

    await get(url, {
      responseKind: "turbo-stream"
    })

    this.toggleLoading()
  }

  toggleLoading() {
    this.loading = !this.loading
    this.paginationLoadingTarget.classList.toggle("hidden")
  }
}
