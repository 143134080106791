import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { fadeTimeout: Number, fade: Boolean }

  connect() {
    if (this.fadeValue == true)
      this.timeoutId = setTimeout(() => { this.close() }, this.fadeTimeoutValue || 3000);
  }

  close() {
    this.element.remove();
  }

  disconnect() {
    this.timeoutId && clearTimeout(this.timeoutId);
  }

}
