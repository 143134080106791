import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["el"]
  static values = {
    class: String,
  }

  toggle() {
    this.element.classList.toggle("toggle-class-active")
    this.elTarget.classList.toggle("toggle-class-el-active")
    this.elTarget.classList.toggle(this.classValue)
  }
}
