import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { debounce } from "lodash"

export default class extends Controller {
  static values = {
    url: String,
    current: Number,
    debounce: { type: Number, default: 500 },
  }

  static targets = ["input", "select", "hidden", "text", "container", "options", "unselect", "badge"]

  blurListener = this.close.bind(this);
  keyDownListener = this.moveAndSelect.bind(this);
  inputKeyDownListener = this.inputKeyDown.bind(this);
  modalSuccessListener = this.modalSuccess.bind(this);
  modalLoadListener = this.modalLoad.bind(this);

  connect() {
    this.fetch = debounce(this.fetch, this.debounceValue)

    if (this.selectTarget.id === "") {
      this.selectTarget.id = Math.random().toString(36)
    }

    this.selectTarget.addEventListener('keydown', this.keyDownListener, false);
    this.selectTarget.addEventListener('modal:success', this.modalSuccessListener, false);
    this.selectTarget.addEventListener('modal:loaded', this.modalLoadListener, false);
    this.inputTarget.addEventListener('keydown', this.inputKeyDownListener, false);

    document.addEventListener('mousedown', this.blurListener, false)
  }

  disconnect() {
    this.selectTarget.removeEventListener('keydown', this.keyDownListener, false);
    this.selectTarget.removeEventListener('modal:success', this.modalSuccessListener, false);
    this.selectTarget.removeEventListener('modal:loaded', this.modalLoadListener, false);
    this.inputTarget.removeEventListener('keydown', this.inputKeyDownListener, false);

    document.removeEventListener('mousedown', this.blurListener, false)
  }

  fetch() {
    let params = new URLSearchParams()

    params.append("search", this.inputTarget.value)
    params.append("target", this.selectTarget.id)

    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream"
    })
  }

  show() {
    this.inputTarget.classList.remove("hidden")
    this.inputTarget.focus()
  }

  select(e) {
    let el = e.currentTarget;

    this._select(el)
  }

  selectElement(el) {
    if (el.dataset.template == "new") return

    this._select(el)
  }

  _select(el) {
    if(el.dataset.turbo === "true") return

    this.badgeTarget.classList.add("hidden")

    this.hiddenTarget.value = el.dataset.value;
    this.textTarget.innerHTML = el.dataset.text;

    this.unselectTarget.classList.remove("hidden")
    this.reset()
  }

  unselect(e) {
    this.hiddenTarget.value = "";
    this.textTarget.innerText = this.textTarget.dataset.placeholder

    this.unselectTarget.classList.add("hidden")
    this.badgeTarget.classList.add("hidden")

    e.preventDefault() && e.stopPropagation()
  }

  reset() {
    this.hide()

    this.inputTarget.value = "";
  }

  close(event) {
    if (this.containerTarget.contains(event.target)) return

    this.reset()
  }

  hide() {
    this.selectTarget.innerHTML = ""
    this.inputTarget.classList.add("hidden")
  }

  moveAndSelect(event) {
    const target = event.target
    const key = event.code

    switch (key) {
      case 'Enter':
      case 'Space':
        this.selectElement(target)
        break;
      case 'Tab':
      case 'ArrowDown':
        let next = target.nextElementSibling

        event.preventDefault() && event.stopPropagation()

        if (next) {
          this.focusParentOrChild(next)
        } else {
          this.selectFirstOption()
        }
        break;
      case 'ArrowUp':
        // não funciona para "adicionar pois é um a"
        let previous = target.previousElementSibling

        event.preventDefault() && event.stopPropagation()

        if (previous) this.focusParentOrChild(previous)
        break;
      case 'Escape':
        this.reset()
    }
  }

  focusParentOrChild(elem) {
    if(elem.tabIndex === 0) {
      elem.focus()
    } else if (elem.firstElementChild.tabIndex === 0) {
      elem.firstElementChild.focus()
    }
  }

  inputKeyDown(event) {
    const key = event.code

    switch (key) {
      case 'Enter':
        event.preventDefault() && event.stopPropagation()
        break;
      case "ArrowDown":
        event.preventDefault() && event.stopPropagation()

        this.selectFirstOption()
        break;
      case 'Escape':
        this.reset()
        break;
      case 'Tab':
        if (this.inputTarget.value == "") this.reset()
      }
  }

  selectFirstOption() {
    const firstOption = this.optionsTargets && this.optionsTargets[0]

    if (firstOption) this.focusParentOrChild(firstOption)
  }

  modalLoad() {
    this.hide()
  }

  async modalSuccess(event) {
    let expectedBody = await event.detail.fetchResponse.responseText

    try {
      const data = JSON.parse(expectedBody)

      this.hiddenTarget.value = data["id"]
      this.textTarget.innerText = data["title"]
      this.badgeTarget.classList.remove("hidden")
      this.unselectTarget.classList.remove("hidden")

      this.reset()
    } catch (error) {
      this.unselect(event)
      this.show()
    }
  }
}
