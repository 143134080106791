import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = ["container", "input"]

  static values = {
    animation: {
      type: String, default: "150" },
    handle: { type: String, default: ".handle" }
  }

  initialize() {
    this.end = this.end.bind(this)
  }

  connect() {
    this.sortable = new Sortable(this.containerTarget, {
      ...this.defaultOptions,
      ...this.options
    })

    this.update()
  }

  disconnect() {
    this.sortable.destroy()
    this.sortable = undefined
  }

  end() {
    this.update()
  }

  update() {
    const total = this.inputTargets.length;

    for(var i = 0; i < total; i++) {
      this.inputTargets[i].value = (i + 1);
    }
  }

  get defaultOptions() {
    return {
      ghostClass: "sortable-ghost",
      dragClass: "sortable-drag"
    }
  }

  get options() {
    return {
      animation: this.animationValue,
      handle: this.handleValue,
      onEnd: this.end
    }
  }
}
