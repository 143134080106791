import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["add", "subtract", "addTotal", "total"]

  addTargetConnected(_element) {
    this.calc()
  }

  addTargetDisconnected(_element) {
    this.calc()
  }

  calc() {
    let total = this.calcTotal()

    this.updateTotal(total)
  }

  calcTotal() {
    if (!this.hasAddTarget) return 0

    let total = 0;

    this.addTargets.forEach((element) => {
        if (element.offsetParent === null)
          return

        total += Number(element.value)
      });

    return total
  }

  updateTotal(total) {
    this.addTotalTarget.innerText = this.format(total)
    this.totalTarget.innerText = this.format(total - Number(this.subtractTarget.value))
  }

  format(number) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number)
  }

}
