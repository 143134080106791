import { patch } from "@rails/request.js"
import SharedSortable from "./shared-sortable"

export default class extends SharedSortable {

  async updateItemState(item, clone, to) {
    const data = new FormData()
    const state = to.id

    // state enum id
    data.append("state", state)

    this.updateOpportunityClassWhenUpdating(item, clone)

    let res = await patch(item.dataset.updateStateUrl, { body: data })

    this.updateItemAndStateColumn(res, item, clone, state)
  }

  updateItemAndStateColumn(res, item, clone, state) {
    if (res.ok) {
      this._handleBaseUpdateItemSuccess(item)

      // dropped item
      item.remove()

      // getting the state group column
      const stateColumn = document.querySelector(".step-container[data-state='" + state + "']")

      if(stateColumn) {
        stateColumn.prepend(clone);
      } else {
        clone.remove()
      }
    } else {
      this._handleUpdateItemError(item, clone)
    }
  }
}
