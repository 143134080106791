import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ["wrapper", "body"]

  connect() {
    enter(this.wrapperTarget)
    enter(this.bodyTarget)

    const event = new Event('modal:loaded');

    document.dispatchEvent(event);
    document.addEventListener('turbo:submit-end', this.handleSubmitListener)
  }

  disconnect() {
    document.removeEventListener('turbo:submit-end', this.handleSubmitListener)
  }

  close() {
    leave(this.wrapperTarget)
    leave(this.bodyTarget).then(() => {
      // Remove the modal element after the fade out so it doesn't blanket the screen
      this.element.remove()
    })

    // limpando src para modal abrir novos modais
    this.element.closest("turbo-frame").src = undefined
  }

  handleKeyup(e) {
    if (e.code == "Escape") {
      this.close()
    }
  }

  handleSubmitListener = this.handleSubmit.bind(this)

  // remover inter-dependencia de evento e retorno do form
  async handleSubmit(e) {
    if (e.detail.success) {
      this.close()

      const event = new CustomEvent('modal:success', { detail: e.detail });

      try {
        let expectedBody = await event.detail.fetchResponse.responseText
        const data = JSON.parse(expectedBody)

        if(data && data.meta && data.meta.target) {
          let eventTarget = document.getElementById(data.meta.target)
          eventTarget && eventTarget.dispatchEvent(event);
        }
      } catch (error) {
        console.log(error)
      }

      document.dispatchEvent(event);
    }
  }
}
