import { Controller } from "@hotwired/stimulus"
import ApexCharts from "apexcharts"

export default class extends Controller {
  static targets = ["container"]
  static values = {
    series: Array,
    colors: Array
  }

  connect() {
    this.chart = new ApexCharts(this.containerTarget, {
      ...this.options
    });

    this.chart.render()
  }

  disconnect() {
    this.chart.destroy()
    this.chart = undefined
  }

  get options() {
    return {
      series: this.seriesValue,

      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },

      plotOptions: {
        bar: {
          columnWidth: '35%',
          horizontal: false,
          borderRadius: 3
        },
      },

      xaxis: {
        type: "category",
      },

      colors: this.colorsValue,

      fill: {
        opacity: 0.8,
      }
    };
  }
}
