import { Controller } from "@hotwired/stimulus"
import { Sortable } from 'sortablejs/modular/sortable.core.esm';
import { patch } from "@rails/request.js"

export default class extends Controller {
  static values = {
    animation: { type: String, default: "150" },
    handle: { type: String, default: ".handle" }
  }

  // move to values
  stateOptions = ["won", "lost", "close"]

  initialize() {
    this.end = this.end.bind(this)
    this.start = this.start.bind(this)
  }

  connect() {
    this.sortable = new Sortable(this.element, {
      ...this.defaultOptions,
      ...this.options
    })
  }

  disconnect() {
    this.sortable.destroy()
    this.sortable = undefined
  }

  start({ clone }) {
    let dropzone = document.getElementById("pipeline-dropzone")

    clone.classList.add("opportunity-moving")

    dropzone.classList.remove("hidden")
  }

  async end({ item, clone, from, to }) {
    let dropzone = document.getElementById("pipeline-dropzone")

    dropzone.classList.add("hidden")

    if (from.id === to.id) return
    if (!item.dataset.updateUrl) return
    if (!from.dataset.stepId) return

    if (this.stateOptions.indexOf(to.id) !== -1) {
      await this.updateItemState(item, clone, to)
    } else {
      await this.updateItemStep(item, clone, from, to)
    }

    to.dataset.present = true

    if (from.childElementCount === 0) {
      from.dataset.present = 'false'
    }
  }

  async updateItemState(item, clone, to) {
    const data = new FormData()

    // state enum id
    data.append("state", to.id)

    this.updateOpportunityClassWhenUpdating(item, clone)

    let res = await patch(item.dataset.updateStateUrl, { body: data })

    this.updateItem(res, item, clone)
  }

  async updateItemStep(item, clone, from, to) {
    const data = new FormData()
    const fromId = (item.dataset.oldStepId !== undefined) ? item.dataset.oldStepId : from.dataset.stepId;

    data.append("from_id", fromId)
    data.append("to_id", to.dataset.stepId)

    this.updateOpportunityClassWhenUpdating(item, clone)

    let res = await patch(item.dataset.updateUrl, { body: data, responseKind: "turbo-stream" })

    this.updateAndRemoveClone(res, item, clone)
  }

  updateOpportunityClassWhenUpdating(item, clone) {
    item.classList.add("opportunity-moving")

    item.querySelector("[data-handler]").classList.remove("handle")
    clone.querySelector("[data-handler]").classList.remove("handle")
  }

  updateAndRemoveClone(res, item, clone) {
    this._updateItem(res, item, clone, true)
  }

  updateItem(res, item, clone) {
    this._updateItem(res, item, clone, false)
  }


  _updateItem(res, item, clone, removeClone) {
    if (res.ok) {
      this._handleUpdateItemSuccess(item, clone, removeClone)
    } else {
      this._handleUpdateItemError(item, clone)
    }
  }

  _handleUpdateItemSuccess(item, clone, removeClone) {
    this._handleBaseUpdateItemSuccess(item)

    if (removeClone) {
      clone.remove()
    } else {
      // dropped item
      item.remove()
    }
  }

  _handleBaseUpdateItemSuccess(item) {
    item.classList.remove("opportunity-move-has-error")
    item.classList.remove("opportunity-moving")

    item.querySelector("[data-handler]").classList.add("handle")

    delete item.dataset.oldStepId
  }

  _handleUpdateItemError(item, clone) {
    item.remove()

    clone.classList.remove("opportunity-moving")
    clone.classList.add("opportunity-move-has-error")
  }

  get defaultOptions() {
    return {
      ghostClass: "sortable-ghost",
      dragClass: "sortable-drag"
    }
  }

  get options() {
    return {
      animation: 0, //this.animationValue,
      handle: this.handleValue,
      onEnd: this.end,
      onStart: this.start,
      group: {
        name: "shared",
        pull: "clone"
      },
      sort: false,
      // checar se fica mais rapido com essa opção
      forceFallback: true,
      emptyInsertThreshold: 20
    }
  }
}
