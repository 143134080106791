import { Controller } from "@hotwired/stimulus"
import ApexCharts from "apexcharts"

export default class extends Controller {
  static targets = ["container"]
  static values = {
    series: Array,
    xaxisMax: Number
  }

  connect() {
    this.chart = new ApexCharts(this.containerTarget, {
      ...this.options
    });

    this.chart.render()
  }

  disconnect() {
    this.chart.destroy()
    this.chart = undefined
  }

  format(number) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number)
  }


  get options() {
    return {
      series: this.seriesValue,

      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },

      plotOptions: {
        bar: {
          columnWidth: '35%',
          horizontal: true,
          borderRadius: 3
        },
      },

      dataLabels: {
        enabled: false,
      },

      xaxis: {
        type: "numeric",
        max: this.xaxisMaxValue,
        labels: {
          formatter: function (val) {
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumSignificantDigits: 3 }).format(val)
          }
        }
      },

      tooltip: {
        y: {
          formatter: function (value) {
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: 0 }).format(value)
          }
        },

        x: {
          formatter: function () {
            return null
          }
        }
      },

      yaxis: {
        show: false,
      },

      fill: {
        opacity: 0.8,
      }
    };
  }
}
