import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab"]
  static values = {
    activeClass: { type: String, default: "active" }
  }

  toggle(event) {
    for(var i = 0; i < this.tabTargets.length; i++) {
      this.tabTargets[i].classList.remove(this.activeClassValue)
    }

    event.target.classList.add(this.activeClassValue)
  }
}
