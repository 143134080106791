import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["loader", "frame"]

  frameTargetConnected(target) {
    target.addEventListener('turbo:before-fetch-request', this.beforeFetchRequest.bind(this), false);
    target.addEventListener('turbo:frame-load', this.afterFetchRequest.bind(this), false);
  }

  frameTargetDisconnect(target) {
    target.removeEventListener('turbo:before-fetch-request', this.beforeFetchRequest.bind(this), false);
    target.addEventListener('turbo:frame-load', this.afterFetchRequest.bind(this), false);
  }

  beforeFetchRequest(event) {
    if(event.target.id === this.frameTarget.id) {
      this.frameTarget.classList.add("hidden")
      this.loaderTarget.classList.remove("hidden")
    }
  }

  afterFetchRequest(event) {
    if (event.target.id === this.frameTarget.id) {
      this.loaderTarget.classList.add("hidden")
      this.frameTarget.classList.remove("hidden")
    }
  }

}
