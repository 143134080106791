import TomSelect from "tom-select"

import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import { debounce } from "lodash"

export default class extends Controller {
  static values = {
    url: String,
    maxItems: Number,
    debounce: { type: Number, default: 300 },
    labelField: { type: String, default: "title" },
  }

  static classes = ["icon"]

  connect() {
    // Verificar necessidade usando em conjunto com TomSelect
    this.load = debounce(this.load, this.debounceValue)

    this.select = new TomSelect(this.element, {
      ...this.defaultOptions,
    });
  }

  disconnect() {
    this.select.destroy()
    this.select = undefined
  }

  async load(query, callback) {
    let params = new URLSearchParams()

    params.append("search", query)

    const resp = await get(`${this.urlValue}?${params}`, {
      responseKind: "json"
    })

    if(resp.ok) {
      const json = await resp.json

      callback(json);
    } else {
      callback();
    }
  }

  optionRender(item, escape) {
    return `
      <div role="option" class="hover:bg-indigo-200 text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
        <div class="flex items-center">
          <span class="block" title="${escape(item[this.labelFieldValue])}">
            ${escape(item[this.labelFieldValue])}
          </span>
        </div>
        <i class="${this.iconClass} ph text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4"></i>
      </div>`
  }

  itemRender(item, escape) {
    return `
      <div class="mb-2 mr-2 inline-flex item items-center space-x-2 p-2 bg-sky-100 rounded-md">
        <span title="${escape(item[this.labelFieldValue])}">
          ${escape(item[this.labelFieldValue])}
        </span>
      </div>`
  }

  noResults(data, escape) {
    return `
      <div class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
        <div class="flex items-center">
          <span class="block truncate">
            Sem resultados para <b>${escape(data.input)}</b>
          </span>
        </div>
      </div>`
  }

  get defaultOptions() {
    return {
      valueField: "id",
      labelField: this.labelFieldValue,
      searchField: this.labelFieldValue,
      persist: false,
      closeAfterSelect: true,
      maxItems: (this.maxItemsValue || null),

      plugins: this.defaultPlugins,

      load: this.load.bind(this),

      render: {
        option: this.optionRender.bind(this),
        item: this.itemRender.bind(this),
        no_results: this.noResults
      }
    }
  }

  get defaultPlugins() {
    return {
      remove_button: {
        title: "Remover",
        className: "remove p-1 px-2 hover:text-sky-900 hover:bg-sky-50 rounded-md"
      },
    }
  }
}
